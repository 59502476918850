header {
    position: relative;
    z-index: 10;
    border-bottom: 25px solid $color2;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.5);
    background: #FFF;

    .top {
        position: relative;
        height: 50px;

        .logo {
            position: absolute;
            top: 5px;
            left: 0px;
            height: 40px;
        }

        .call-button,
        .search-toggle,
        .nav-toggle {
            position: absolute;
            right: -10px;
            top: 0px;
            width: 40px;
            height: 50px;
            text-align: center;
            text-decoration: none;
            color: $color1;

            &:hover {
                color: $color2;
            }

            span {
                font-size: 24px;
                line-height: 50px;
            }
        }
        
        .call-button {
            right: 30px;
            color: $color2;
        }

        .search-toggle {
            right: 70px;
            color: $color2;
        }

        .widbm {
            display: none;
        }

        .logos {
            display: none;
        }
    }

    nav {
        position: absolute;
        left: 0px;
        top: 75px;
        width: 100%;
        background: $color2;
        display: none;
        z-index: 10;

        .container-fluid {
            padding: 0;

            ul {
                list-style: none;
                padding: 0px;
                margin: 0px;

                li {
                    border-top: 1px solid rgba(#FFF, 0.2);

                    a {
                        display: block;
                        color: #FFF;
                        text-decoration: none;
                        line-height: 30px;
                        padding: 5px 15px;
                        user-select: none;

                        &:hover {
                            background: shade($color1, 5%);
                        }

                        span {
                            display: block;
                            font-size: 12px;
                            float: right;
                            line-height: 30px !important;
                            transform: rotate(180deg);
                            transition: all 0.3s;

                            &.toggle {
                                transform: rotate(0deg);
                            }
                        }

                        &.active {
                            background: $color2;

                            &:hover {
                                background: shade($color2, 5%);
                            }
                        }
                    }
                }
            }

            > ul {
                > li {
                    > ul {
                        background: tint($color1, 10%);
                        display: none;

                        > li {
                            a {
                                white-space: nowrap;
                                padding-left: 25px;

                                &:hover {
                                    background: tint($color1, 5%);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

// ***************************************************************** 
// SM
// ***************************************************************** 

@media (min-width: $screen-sm-min) {

    header {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        border-bottom: 0px;

        .top {
            height: 80px;

            .logo {
                top: 10px;
                height: 60px;
                z-index: 10;
            }
            
            .call-button,
            .search-toggle,
            .nav-toggle {
                display: none;
            }

            .widbm {
                display: block;
                position: absolute;
                right: 340px;
                top: 10px;
            }

            .logos {
                position: absolute;
                right: 0px;
                top: 27px;
                display: block;

                a {
                    text-decoration: none;
                    color: $color2;
                    line-height: 24px;
                    margin-left: 30px;

                    span {
                        font-size: 24px;
                    }

                    &.tel {
                        white-space: nowrap;
                        color: $color2;
                        font-family: $font1;
                        font-weight: 700;
                        font-size: 18px;
                        vertical-align: 4px;

                        span {
                            font-size: 20px;
                            vertical-align: -3px;
                        }
                    }
                }
            }
        }

        nav {
            display: block !important;
            position: static;

            .container-fluid {

                ul {
                    li {
                        border-top: none;

                        a {
                            padding: 5px 8px;

                            span {
                                display: none;
                            }
                        }
                    }
                }

                > ul {
                    text-align: center;

                    > li {
                        position: relative;
                        display: inline-block;

                        > a {
                            &:hover {
                                background: $color2;
                            }
                        }

                        > ul {
                            position: absolute;
                            top: 100%;
                            left: 0px;
                            background: #FFF;
                            text-align: left;
                            display: block;
                            z-index: 10;
                            max-height: 0;
                            overflow: hidden;
                            transition: all 0.5s;
                            border-bottom-right-radius: 3px;
                            border-bottom-left-radius: 3px;
                            box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.25);

                            > li {
                                display: block;

                                a {
                                    color: #5b5b5b;
                                    padding: 5px 8px;
                                    
                                    &.active,
                                    &.active:hover,
                                    &:hover {
                                        background: none;
                                        color: $color2;
                                    }
                                }
                            }
                        }

                        &:last-child {
                            ul {
                                left: auto;
                                right: 0px;
                            }
                        }

                        &:hover {
                            ul {
                                max-height: 250px;
                            }
                        }
                    }
                }
            }
        }
    }
}

// ***************************************************************** 
// MD
// ***************************************************************** 

@media (min-width: $screen-md-min) {

    header {
        
        nav {
            .container-fluid {

                ul {
                    li {
                        a {
                            padding: 5px 15px;
                        }
                    }
                }

                > ul {
                    > li {
                        > ul {
                            > li {
                                a {
                                    padding: 5px 15px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

}

// ***************************************************************** 
// LG
// ***************************************************************** 

@media (min-width: $screen-lg-min) {
    header {

        .top {
            height: 110px;

            .logo {
                height: 95px;
                padding-top: 0gulp watchpx;
            }

            .widbm {
                position: absolute;
                right: 210px;
                top: 26px;
            }

            .logos {
                top: 40px;

                a {
                    span {
                        font-size: 32px;
                    
                        &.icon-nvm {
                            font-size: 40px;
                        }
                        &.icon-rvm {
                            font-size: 40px;
                        }
                        &.icon-qualis {
                            font-size: 40px;
                        }
                    }

                    &.tel {
                        position: absolute;
                        top: 83px;
                        right: 0px;
                        color: #FFF;
                    }
                }

            }
        }

        nav {
            .container-fluid {

                ul {
                    li {
                        a {
                            padding: 10px 15px;
                        }
                    }
                }

                > ul {
                    > li {
                        > ul {
                            > li {
                                a {
                                    padding: 10px 15px;
                                }
                            }
                        }

                        &:last-child {
                            ul {
                                left: 0px;
                                right: auto;
                            }
                        }
                    }
                }
            }
        }
    }
}