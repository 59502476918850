@font-face {
    font-family: 'icomoon';
    src: url('fonts/icomoon.eot?aimdt4');
    src: url('fonts/icomoon.eot?aimdt4#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?aimdt4') format('truetype'),
    url('fonts/icomoon.woff?aimdt4') format('woff'),
    url('fonts/icomoon.svg?aimdt4#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-taxaties:before {
    content: "\e909";
}

.icon-google-plus:before {
    content: "\e900";
}

.icon-linkedin:before {
    content: "\e90f";
}

.icon-pinterest:before {
    content: "\e916";
}

.icon-facebook:before {
    content: "\e901";
}

.icon-instagram:before {
    content: "\e902";
}

.icon-mail:before {
    content: "\e903";
}

.icon-phone:before {
    content: "\e905";
}

.icon-menu:before {
    content: "\e008";
}

.icon-twitter:before {
    content: "\e928";
}

.icon-youtube:before {
    content: "\e929";
}

.icon-search:before {
    content: "\e906";
}

.icon-arrow-down:before {
    content: "\e000";
}

.icon-arrow-right:before {
    content: "\e001";
}

.icon-arrow-left:before {
    content: "\e002";
}

.icon-arrow-up:before {
    content: "\e012";
}

.icon-aankoopmakelaar:before {
    content: "\e904";
}

.icon-funda:before {
    content: "\e907";
}

.icon-huisverkopen:before {
    content: "\e908";
}

.icon-vbomakelaar:before {
    content: "\e912";
}