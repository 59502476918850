#tekst {
    .employee {
        img {
            width: 100%;
            margin-bottom: 30px;
        }

        h2 {
            margin: 0 0 5px 0;
        }

        h3 {
            margin: 0 0 5px 0;
            font-size: 16px;
            color: #000;
            font-weight: bold;
        }

        .phone {
            margin: 0 0 5px 0;
        }
        .linkedin{
            margin:10px 0 0 0;
            width:100px;
            transition:1s all ease-in-out;
            &:hover{
                filter: grayscale(100%);
            }
        }
    }

    .contact {
        background: $color3;
        margin-bottom: 30px;
        color: #fff;
        padding-top: 10px;
        padding-bottom: 10px;
        
        h2 {
            color: #fff;
        }
        
        label {
            display: none;
        }
    }
}

// ***************************************************************** 
// SM
// ***************************************************************** 

@media (min-width: $screen-sm-min) {
    #tekst {
        .employee {
            img {
                width: 60%;
            }
        }
    }
}

// ***************************************************************** 
// MD
// ***************************************************************** 

@media (min-width: $screen-md-min) {
}

// ***************************************************************** 
// LG
// ***************************************************************** 

@media (min-width: $screen-lg-min) {
}