footer {
  text-align: center;

  h3 {
    font-size: 22px;
    color: #FFF;
    margin-bottom: 30px;
    text-transform: none;
  }

  a {
    color: #FFF;
    text-decoration: none;
  }

  dl {
    display: inline-block;
    margin: 0 0 15px 0;

    dt {
      display: inline-block;
      font-family: $font1;
      font-weight: bold;
      text-align: center;
    }

    dd {
      margin-left: 0;
      margin-bottom: 15px;

      a.tel {
        font-family: $font1;
        color: #FFF;
        font-weight: 700;
        font-size: 20px;
      }

      address {
        font-style: normal;
      }
    }
  }

  ul.direct {
    display: block;
    padding-left: 15px;
    margin: 0 0 30px 0;
  }

  ul.social {
    font-size: 0px;
    margin-bottom: 0px;
    padding-left: 0px;
    list-style: none;

    li {
      display: inline-block;
      margin-right: 10px;

      &:last-child {
        margin-right: 0px;
      }

      a {
        display: inline-block;
        color: #FFF;
        text-decoration: none;

        span {
          text-align: center;

          &:before {
            display: block;
            width: 60px;
            height: 60px;
            line-height: 60px;
            background: $color3;
            font-size: 32px;
            border-radius: 30px;
          }
        }

        &:hover {
          span:before {
            background: shade($color2, 10%);
          }
        }
      }
    }

  }

  .bottom {
    margin-top: 30px;
    border-top: 1px solid rgba(255, 255, 255, 0.4);
    padding-top: 15px;
    color: #fff;

    span {
      display: inline-block;
      font-size: 32px;
      margin: 15px;

      &.icon-vbo {
        font-size: 120px;
      }

      &.icon-nvm {
        font-size: 40px;
      }

      &.icon-qualis {
        font-size: 32px;
      }

      &.icon-rvm {
        font-size: 32px;
      }
    }

    .copyright {
      margin-top: 15px;
      font-size: 14px;

      a {
        color: #fff;
        text-decoration: underline;
      }
    }
  }

  .btn {
    background-color: $color3;
    vertical-align: middle;
    padding: 6px 15px;
  }
}

// ***************************************************************** 
// SM
// ***************************************************************** 

@media (min-width: $screen-sm-min) {

}

// ***************************************************************** 
// MD
// ***************************************************************** 

@media (min-width: $screen-md-min) {
  footer {
    text-align: left;
    padding-bottom: 30px;

    dl {
      width: 100%;
      margin: 0;

      dt {
        float: left;
        width: 30%;
        text-align: left;
      }

      dd {
        float: left;
        width: 70%;
      }
    }

    ul.direct {
      padding-left: 20px;
      margin-bottom: 0px;
    }

    .bottom {
      text-align: center;

      span:first-child {
        margin-left: 0px;
      }
    }
  }
}

// ***************************************************************** 
// MD
// ***************************************************************** 

@media (min-width: $screen-md-min) {
  footer {
    text-align: left;
    padding-bottom: 30px !important;

    dl {
      width: 100%;
      margin: 0;

      dt {
        float: left;
        width: 30%;
        text-align: left;
      }

      dd {
        float: left;
        width: 70%;
      }
    }
  }
}

// *****************************************************************
// LG
// ***************************************************************** 

@media (min-width: $screen-lg-min) {
  footer {
    padding-bottom: 15px !important;

    .bottom {
      text-align: left;
    }
  }
}